body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.math {
  vertical-align: -0.671ex;
  /* width: 3.712ex; */
  /* height: 2.509ex; */
}
.math2 {
  vertical-align: -0.241ex;
  /* width: 3.712ex; */
  /* height: 2.509ex; */
}

#gdoc div {
  width: 100%;
  max-width: fit-content;
  padding-top: 0;
}